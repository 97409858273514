import { Fragment, useContext, useEffect, useRef, useState } from "react";
import "./Explore.css";
import { 
  fetchTokenSymbol, 
  getPoolsFromGraph, 
  shouldRenderPool, 
  tabletBreakpoint, 
  tokenData 
} from "../../utils/Utils";
import { APP_DATA_CONTEXT, FEATURED_POOLS, FEATURED_POOLS_MANAGER_CONTEXT, POOL_DATA, WALLET_DATA_CONTEXT } from "../../utils/Interfaces";
import LendingPool from "../../components/LendingPool/LendingPool";
import AssetRow from "../../components/AssetsRow/AssetRow";
import { Alert } from "@material-ui/lab";
import { PuffLoader } from "react-spinners";
import { useScreenSize } from "../../utils/useScreenSize";
import { AppDataContext } from "../../context/AppDataContext";
import { WalletDataContext } from "../../context/WalletDataContext";
import { PoolDataContext } from "../../context/PoolDataContext";
import Slideshow from "../../components/Slideshow/Slideshow";
import BorrowFilters from "../../components/BorrowFilters/BorrowFilters";
import WrongNetwork from "../../components/WrongNetwork/WrongNetwork";
import { Info, ShoppingBag } from "react-feather";
import PoolRequestButton from "../../components/PoolRequestButton/PoolRequestButton";
import { FeaturedPoolsManagerContext } from "../../context/FeaturedPoolsManagerContext";
import PoolCategories from "../../components/PoolCategories/PoolCategories";
declare var window: any;

const Explore = () => {

  const [pools, setPools] = useState<any[]>([]);
  const [assetRows, setAssetRows] = useState<any[]>([]);
  const [selectedPool, setSelectedPool] = useState<any>();
  const [colFilter, setColFilter] = useState<string[]>([]);
  const [lendFilter, setLendFilter] = useState<string[]>([]);
  const { screenWidth } = useScreenSize();
  const [queryPool, setQueryPool] = useState<string>();
  const [loadedQueryPool, setLoadedQueryPool] = useState<boolean>(false);
  const [selectedFeaturedPools, setSelectedFeaturedPools] = useState<FEATURED_POOLS | undefined>();
  const poolLoadedCount = useRef<number>(0);
  const fetchingPools = useRef<boolean>(false);
  const {
    tokenPrices,
    liquidityRange,
    aprRange,
    dateRange,
    fullPoolData,
    calculateLTV,
  } = useContext(AppDataContext) as APP_DATA_CONTEXT;
  const { chainId, account } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;
  const { featuredPoolsList, isPoolFeatured, featuredPoolsPrice, getFeaturedPoolData } = useContext(FeaturedPoolsManagerContext) as FEATURED_POOLS_MANAGER_CONTEXT;

  useEffect(() => {
    poolLoadedCount.current = 0;
    getPools();
    window.addEventListener('click', onClick);
    return () => {
      window.removeEventListener('click', onClick);
    };
  // eslint-disable-next-line
  }, [chainId]);

  useEffect(() => {
    if (!loadedQueryPool)
      selectQueryPool();
  // eslint-disable-next-line
  }, [fullPoolData]);

  useEffect(() => {
    if (pools.length > 0)
    updateAssetRowData(pools);
  // eslint-disable-next-line
  }, [selectedFeaturedPools, pools, featuredPoolsList]);

  useEffect(() => {
    if (selectedPool)
      setQueryPool(selectedPool.id);
  }, [selectedPool]);

  // check if pool was passed in the URL and select it if needed
  const selectQueryPool = () => {
    const pathname = window.location.pathname;
    const queryPoolAddress = pathname.substring(pathname.lastIndexOf("/") + 1).toLowerCase();
    setQueryPool(queryPoolAddress);
    for (const pool of pools) {
      // don't load the pool if query pool has already been loaded once
      if (pool.id === queryPoolAddress) {
        setSelectedPool(fullPoolData[pool.id]);
      }
    }
    setLoadedQueryPool(true && pools.length > 0);
  }

  const onClick = (e: any) => {
    const element = document.querySelector(".topnav-header.desktop");
    if (element?.contains(e.target))
      setSelectedPool(undefined);
  }

  const getPools = async() => {
    if (fetchingPools.current || !chainId) return;
    try {
      let fetchedPools:any;
      let count = 0;

      // try to fetch pools 3 times
      // use the fallback after the first try
      while (fetchedPools === undefined && count < 3) {
        fetchedPools = await getPoolsFromGraph(
          chainId, 
          // don't use fallback on first try
          count === 0 ? false : true,
        );
        count++;
      }

      if (!fetchedPools) {
        console.log("Failed to fetch pools");
        return;
      }

      // arbitrary start time to filter pools by.
      // const arbitraryStart = 1648851730;
      const poolBlacklist:string[] = [
        "0x0f90c40f75f3cadd196d62c364ea53bb591bdc3d",
        "0x48e71a8916ebf807af965e925c09919cdee2ee5c",
        "0xE2f0B6D124917Ed8E08D0fB72F2B627d5253Fa53",
        "0x615c45A24577b8d5B0ACa0427bFcF8cAb03ba000",
        "0x3dD854630F428DB7520403F81350e6191da79B80",
        "0x0FE03e52483ba2536Cd6B2d796b1ed35eeD31c87",
        "0xa9d51ed02c7E3Ae124F780E9538504Ded68F859a",
        "0xf705aE9CF3AC00451e74Da9752a6c12406E466Fc",
        "0xaaf300f6d914907eec8f8df43c11c5efd4bf86f0",
        "0x07431bf27d0f5296342a9e4f3fdc41444b474c51",
        "0x98703f8d8f4e8e53f64276a52b75a995270f84d1",
        "0x2b29be3415ef9bf71670ac064789efa122426dbc",
        "0xda97ace113320c8ad1cac0c04d8af5bb9725e029",
        "0x8ee84d838885c2f97e24b0edf7bf12f15e1f69dc",
        "0xed49a8a902eace1e29e646f32e6bb0ae17e643c3",
        "0xaf9d795d75e77ef282eb181f420cd768de02092b",
        "0xbc59efd013a7d278612abe28f641044a3c334e51",
        "0x7a629ddc45d03386c82037d492b03d183f4e83dc",
        "0x24b33ab67efca7df0c859150cb9afeca0872c662",
        "0x4711614c0ac533ef9fd58f63492d2b5158c99f3f"
      ].map((pool) => pool.toLowerCase());
      // run pools through filters
      let pools = fetchedPools.filter((pool: any) => {
        const isInBlacklist = poolBlacklist.includes(pool.id.toLowerCase());
        const hasBlacklistedToken = 
          pool.colToken.toLowerCase() ===  "0xb3c1c9363064a9abb6b898ed3d4f70f2b9f4a526" || 
          pool.lendToken.toLowerCase() === "0xb3c1c9363064a9abb6b898ed3d4f70f2b9f4a526";
        const isExpired = pool.expiryTime < Date.now() / 1000;
        const isPaused = pool.paused;
        return !isInBlacklist && !isPaused && !isExpired && !hasBlacklistedToken;
      });
      // add owner to pool data
      // the graph returns deployer but the contracts return owner
      pools = pools.map((pool: any) => {
        return {
          ...pool,
          owner: pool.deployer
        }
      });
      if (pools.length > 0) {
        setPools(pools);
        updateAssetRowData(pools);
        let allSymbols:string[] = [];
        Object.values(tokenData).forEach((data) => {
          let supportedNetworks = data.supportedNetworks as number[];
          if (supportedNetworks.includes(chainId)) {
            allSymbols.push(data.symbol.toUpperCase());
          }
        });
        // set filters
        setColFilter(allSymbols);
        setLendFilter(allSymbols);
      }
    } catch (e) {
      // console.log(e);
    }
    fetchingPools.current = false;
  }

  const updateAssetRowData = (pools: any[]) => {
    const assetRowData:any = {}
    let count = 0;

    // const featuredList = selectedFeaturedPools?.filterMethod(pools, tokenPrices);
    let featuredList;
    if (selectedFeaturedPools?.name === "Most Funded")
      featuredList = selectedFeaturedPools?.filterMethod(pools, tokenPrices);
    else if (selectedFeaturedPools?.name === "Featured Pools")
      featuredList = selectedFeaturedPools?.filterMethod(pools, featuredPoolsList);
    else 
      featuredList = selectedFeaturedPools?.filterMethod(pools, tokenPrices);

    for (const pool of pools) {
      // key for asset row
      const key = `${pool.colToken}/${pool.lendToken}`;

      // update or create a new entry for col/lend token combos
      if (assetRowData[key] !== undefined) {
        let poolArr = [...assetRowData[key].pools];
        // check if current pool is part of a featured filter 
        if (featuredList?.includes(pool.id) || pool.id.toLowerCase() === queryPool) 
          poolArr.push(pool);
        assetRowData[key] = {
          ...assetRowData[key],
          pools: poolArr
        }
      } else {
        let poolArr = [];

        // check if current pool is part of a featured filter 
        if (featuredList?.includes(pool.id)) 
          poolArr.push(pool);

        assetRowData[key] = {
          ...assetRowData[key],
          pools: poolArr,
          lendToken: pool.lendToken,
          colToken: pool.colToken
        }

      } 

      if (count++ === pools.length - 1) {
        // extract the groups and set state
        let assetRowArr:any[] = [];
        Object.entries(assetRowData).forEach((data) => {
          assetRowArr.push(data[1]);
        });
        setAssetRows(assetRowArr);
      }
    }
  }

  const shouldRenderRow = (
    assetRowData: {
      colToken: string;
      lendToken: string;
      pools: POOL_DATA[];
    },
  ): boolean => {
    // get token symbols
    const colSymbol = fetchTokenSymbol(assetRowData.colToken, chainId);
    const lendSymbol = fetchTokenSymbol(assetRowData.lendToken, chainId);
    // if an asset row has 0 non-zero balance pools, don't render it
    let hasPoolsOfInterest = false;
    if (
      assetRowData.pools.length > 0 &&
      selectedFeaturedPools?.name === "All Pools"
    ) {
      // collect pools that are in range
      const poolsOfInterest = assetRowData.pools.filter((pool) => {
        const ltv = calculateLTV(pool);
        return shouldRenderPool(
          pool,
          chainId,
          tokenPrices,
          account,
          liquidityRange,
          aprRange,
          dateRange,
          queryPool,
          ltv,
          true
        )
      });
      hasPoolsOfInterest = poolsOfInterest.length > 0;
    } else if (
      selectedFeaturedPools?.name !== "All Pools"
    ) {
      const featuredDeployerPools = assetRowData.pools.filter((pool) => {
        // return featuredList?.includes(pool.id.toLowerCase()) || pool.id.toLowerCase() === queryPool
        const ltv = calculateLTV(pool);
        return shouldRenderPool(
          pool,
          chainId,
          tokenPrices,
          account,
          liquidityRange,
          aprRange,
          dateRange,
          queryPool,
          ltv,
          true
        )
      });
      hasPoolsOfInterest = featuredDeployerPools.length > 0;
    }

    // are either of the tokens in the token filters
    const inColFilter = colFilter.includes(colSymbol.toUpperCase());
    const inLendFilter = lendFilter.includes(lendSymbol.toUpperCase());

    // final show logic
    let show = false;
    if (
      inColFilter &&
      inLendFilter &&
      hasPoolsOfInterest &&
      assetRowData.pools.length > 0
    ) {
      show = true;
    } else show = false;

    return show;
  };

  const shouldBeOpen = (pools: POOL_DATA[], index: number) => {
    let show = false;
    pools.forEach((pool: POOL_DATA) => {
      if (show) return;
      // check if pool is the query pool
      const isQueryPool = pool.id === queryPool;
      show = isQueryPool;
    });
    return show || index === 0;
  }

  const sortAssetRows = (assetRow1: any, assetRow2: any) => {
    if (selectedFeaturedPools?.name === "Featured Pools") {
      // check if any of the pools are featured
      const featuredPool1 = assetRow1.pools.filter((pool: POOL_DATA) => isPoolFeatured(pool.id));
      const featuredPool2 = assetRow2.pools.filter((pool: POOL_DATA) => isPoolFeatured(pool.id));
      // if both have featured pools, sort by which featured pool has a later featuredDuration
      if (featuredPool1.length > 0 && featuredPool2.length > 0) {
        const featuredPool1Duration = getFeaturedPoolData(featuredPool1[0].id)?.featuredDuration;
        const featuredPool2Duration = getFeaturedPoolData(featuredPool2[0].id)?.featuredDuration;
        // if either is undefined, default to 0
        if (featuredPool1Duration === undefined || featuredPool2Duration === undefined) return 0;
        return featuredPool2Duration - featuredPool1Duration;
      } else if (featuredPool1.length > 0) {
        // if only one has featured pools, sort by that one
        return -1;
      } else if (featuredPool2.length > 0) {
        return 1;
      } else {
        // if neither have featured pools, sort by pool count
        return assetRow2.pools.length - assetRow1.pools.length;
      }
    } else {
      return assetRow2.pools.length - assetRow1.pools.length;
    }
  }

  const renderAssetRows = () => {
    // filter out for pools we don't want to show
    // and sort by pool count (for now)
    const filteredAssetRows = assetRows
      .filter((assetRowData: any) => chainId === 5001
        ? true
        : shouldRenderRow(assetRowData)
      )
      .sort(sortAssetRows);
    
    // check if token prices have been loaded yet
    const pricesLoaded = Object.entries(tokenPrices).length > 0;

    // show loading indicator if no pools, no prices, or no featured pools
    // I'm checking featuredPoolsPrice because it's loaded at the same time as featuredPoolsList
    // and is undefined if featuredPoolsList hasn't been loaded yet
    if (pools.length === 0 || !pricesLoaded || featuredPoolsPrice === undefined) {
      return (
        <Alert severity="info" className="no-pools-alert fade-in">
          <span className="no-pools-alert-message-wrapper">
            <ShoppingBag/>
            Loading Markets...
          </span>
          <PuffLoader size={30} className="loader" color="white"/>
        </Alert>
      );
    } else if (filteredAssetRows.length > 0) {
      return (
        <Fragment>
          {filteredAssetRows.map((assetRowData: any, index: number) => 
            <Fragment key={index}>
              <AssetRow
                lendToken={assetRowData.lendToken}
                colToken={assetRowData.colToken}
                pools={assetRowData.pools}
                searchValue={""}
                open={shouldBeOpen(assetRowData.pools, index)}
                key={`${assetRowData.colToken}-${assetRowData.lendToken}`}
              />
            </Fragment>
          )}
          <PoolRequestButton/>
        </Fragment>
      );
    } else if (filteredAssetRows.length === 0 && pricesLoaded) {
      return (
        <Alert severity="info" className="no-pools-alert fade-in">
          <span className="no-pools-alert-message-wrapper">
            <Info/>
            No pools found. Try modifying your filters 
          </span>
        </Alert>
      );
    }
  }

  const renderSlideshow = () => {
    const slideshowContent = [
      {
        title: "Welcome to Vendor Finance",
        body: "We facilitate non-liquidation loans on any asset and fixed terms set by individual lenders.",
        img: require("../../img/slideshow/borrow/1.png"),
      },
      {
        title: "Borrow Tokens Stress Free",
        body: "There are no liquidations! Just make sure you repay on time. If your collateral is worth less than what you borrowed, simply do not repay.",
        img: require("../../img/slideshow/borrow/2.png"),
      },
      {
        title: "Extend Your Loans",
        body: "Before repaying, check if the lender allowed you to extend your loan. You will only need to pay the fee in that case.",
        img: require("../../img/slideshow/borrow/3.png"),
      },
      {
        title: "Create your own pools",
        body: "Use the create pool page to create your own loan and start lending on your own terms today.",
        img: require("../../img/slideshow/borrow/4.png"),
      }
    ]
    return <Slideshow
      content={slideshowContent}
    />
  }

  const renderRightSection = () => {
    if (screenWidth > tabletBreakpoint)
      return (
        <section className="explore-right-section page-right-section">
          {!selectedPool ? (
            renderSlideshow()
          ) : (
            <LendingPool
              poolAddress={selectedPool.id}
              poolData={selectedPool}
              updatePoolData={getPools}
            />
          )}
        </section>
      );
    else {
      if (selectedPool)
        return (
          <section className="explore-right-section modal fade-in">
            <LendingPool
              poolAddress={selectedPool.id}
              poolData={selectedPool}
              updatePoolData={getPools}
            />
          </section>
        );
    }
  }

  return (
    <div className="explore-wrapper fade-in">
      <PoolDataContext.Provider value={{ selectedPool, setSelectedPool, selectedFeaturedPools, setSelectedFeaturedPools }}>
        <section className="explore-left-section left-section">
          <WrongNetwork/>
          <BorrowFilters
            colFilter={colFilter}
            setColFilter={setColFilter}
            lendFilter={lendFilter}
            setLendFilter={setLendFilter}
          />
          <PoolCategories
            pools={pools}
          />
          <div className="explore-asset-rows">
            {renderAssetRows()}
          </div>
        </section>
        {renderRightSection()}
      </PoolDataContext.Provider>
    </div>
  );
}

export default Explore;