import "./TokenModal.css";
import Modal from "../Modal/Modal";
import { useState, useEffect, useContext, Fragment } from "react";
import { fetchTokenLogo, getChainQuery, renderLazyLoad, tokenData } from "../../utils/Utils";
import { APP_DATA_CONTEXT, CREATE_POOL_CONTEXT, SELECTED_TOKEN, WALLET_DATA_CONTEXT } from "../../utils/Interfaces";
import { AppDataContext } from "../../context/AppDataContext";
import { WalletDataContext } from "../../context/WalletDataContext";
import { ethers } from "ethers";
import { CreatePoolContext } from "../../context/CreatePoolContext";
import LineTabs from "../LineTabs/LineTabs";
import ReactTooltip from "react-tooltip";
import { useNavigate } from "react-router-dom";

const TokenModal = (props: {
  selectedInput: string,
  showModal: boolean,
  setShowModal: (val: boolean) => void,
  setSelectedCollateral: (val: any) => void,
  setSelectedLend: (val: any) => void,
  selectedCollateral: SELECTED_TOKEN | any,
  selectedLend: SELECTED_TOKEN | any,
}) => {
  const [filter, setFilter] = useState("");
  const [supportedTokens, setSupportedTokens] = useState({})
  const [loadedToken, setLoadedToken] = useState<any>();
  const [selectedCategory, setSelectedCategory] = useState<string>("Popular");
  // list of accounts that can deploy xGRAIL pools
  const xGrailWhitelist = ["0x9f1A540A4b3C0A1567016171580C119347E55CCB", "0x01Bb7B44cc398AaA2b76Ac6253F0F5634279Db9D"]
  .map((val) => val.toLowerCase());
  // list of categories to filter tokens by
  const tokenCategories = ["All", "Popular", "Stables", "LP"];

  // import contexts
  const { tokenPrices } = useContext(AppDataContext) as APP_DATA_CONTEXT;
  const { provider, chainId, account } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;
  const { setSelectedMintRatio, setSelectedMintRatioButton } = useContext(CreatePoolContext) as CREATE_POOL_CONTEXT;
  const navigate = useNavigate();

  useEffect(() => {
    if (provider && chainId) { 
      setSupportedTokens({});
      getWhitelistedTokens();
    }
  // eslint-disable-next-line
  }, [provider, chainId]);

  useEffect(() => {
    // dynamically load tokens 
    if (loadedToken !== undefined) {
      let data = {
        ...supportedTokens,
        [`${loadedToken.symbol}`]: loadedToken
      }
      setSupportedTokens(data);
    }
  // eslint-disable-next-line
  }, [loadedToken]);

  const getWhitelistedTokens = async () => {
    for (const token in tokenData) {
      //@ts-ignore
      if (!tokenData[token].supportedNetworks.includes(chainId)) {
      //@ts-ignore
        continue;
      };
      // I have no idea why but tokens don't load correctly without the
      // await statement 
      //@ts-ignore
      let supported = await tokenData[token].address[chainId] !== undefined;
      //@ts-ignore
      if (supported) setLoadedToken(tokenData[token]);
    }
  }

  const tokenClicked = (data: {
    symbol: string;
    price: number;
    address: string;
    hasOracle: boolean;
    displayDecimals: number;
    decimals: number
  }) => {
    // check if selecting collateral or lend token
    const tokenType = props.selectedInput === "0"
      ? props.selectedCollateral
      : props.selectedLend;
    // only execute if token is different than current token
    if (data.symbol !== tokenType.symbol) {
      // update selected token data
      if (props.selectedInput === "0") 
        props.setSelectedCollateral(data);
      else 
        props.setSelectedLend(data);
      // reset the lend ratio to 0 if either token is changed
      setSelectedMintRatio(0);
      setSelectedMintRatioButton("Custom");
      navigate(`/create-pool${getChainQuery(chainId)}`);
    }
    // close modal
    props.setShowModal(false);
  };
  
  const manageCategoryFilter = (currentToken: any) => {
    if (selectedCategory === "All") {
      return true;
    } else if (selectedCategory === "Popular") {
      const popularList = [
        // WETH 42161 
        "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
        // WETH 1
        "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
        // CMUMAMI
        "0x1922C36F3bc762Ca300b4a46bB2102F84B1684aB",
        // ARB
        "0x912CE59144191C1204E64559FE8253a0e49E6548",
        // DAI 42161
        "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
        // DAI 1
        "0x6B175474E89094C44Da98b954EedeAC495271d0F",
        // DAI 5001
        "0xB38E748dbCe79849b8298A1D206C8374EFc16DA7",
        // USDC 1
        "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
        // USDC 5001
        "0xc92747b1e4Bd5F89BBB66bAE657268a5F4c4850C",
        // USDC 42161
        "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
        // USDC.e
        "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
        // USDT 5001
        "0xa9b72cCC9968aFeC98A96239B5AA48d828e8D827",
        // cbETH 1
        "0xBe9895146f7AF43049ca1c1AE358B0541Ea49704",
        // cbETH 42161
        "0x2Ae3F1Ec7F1F5012CFEab0185bfc7aa3cf0DEc22",
        // USDbC
        "0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA",
        // RLB
        "0x046EeE2cc3188071C02BfC1745A6b17c656e3f3d",
        // COW
        "0xDEf1CA1fb7FBcDC777520aa7f396b4E015F497aB",
        // wMNT
        "0x8734110e5e1dcF439c7F549db740E546fea82d66",
        // FMDUC
        "0xB3C1c9363064A9ABb6B898ED3D4f70F2B9f4A526",
        // mUSDC
        "0xc92747b1e4Bd5F89BBB66bAE657268a5F4c4850C"
      ];
      if (popularList.includes(currentToken.address[chainId])) return true;
    } else if (selectedCategory === "Stables") {
      return currentToken.category && currentToken.category.toLowerCase() === "stablecoin";
    } else if (selectedCategory === "LP") {
      return currentToken.category && currentToken.category.toLowerCase() === "lp";
    }
  }

  const doesSymbolOverflow = (element: HTMLElement) => {
    return element.offsetWidth > 160;
  }

  const renderTokenSymbol = (symbol: string) => {
    // check if the symbol overflows the container
    const symbolElement = document.createElement("span");
    symbolElement.innerHTML = symbol;
    symbolElement.className = "token-name";
    // symbolElement.className = "token-filter-token-symbol";
    document.body.appendChild(symbolElement);
    const overflow = doesSymbolOverflow(symbolElement);
    document.body.removeChild(symbolElement);
    if (overflow)
      return (
        <Fragment>
          <ReactTooltip
            effect="solid"
            className="tool-tip"
            id={`token-filter-symbol-tooltip-${symbol}`}
          >
            {symbol}
          </ReactTooltip>
          <span 
            className="token-name" 
            data-tip 
            data-for={`token-filter-symbol-tooltip-${symbol}`}
          >
            {symbol}
          </span>
        </Fragment>
      )
    else 
      return (
        <span className="token-name">{symbol}</span>
      )
  }

  // iterate through tokens
  const getTokens = () => {
    return (
      Object.entries(supportedTokens).map((data: any[], index) => {
        try {
          // fetch token data to display on token modal
          const symbol = data[0];
          const tokenData = data[1];
          // ignore non-supported tokens on a given chain
          if (!tokenData.supportedNetworks.includes(chainId)) return <></>;
          if (!manageCategoryFilter(tokenData)) return <></>;
          //@ts-ignore
          const address = ethers.utils.getAddress(tokenData.address[chainId]);
          //@ts-ignore
          const hasOracle = tokenData.hasOracle;
          const decimals = tokenData.tokenDecimals;
          // apply filter
          if (
            symbol.toUpperCase().indexOf(filter.toUpperCase()) === -1 &&
            tokenData.address[chainId].indexOf(filter) === -1
          ) return null;
          // xGRAIL filter
          if (symbol.toUpperCase() === "XGRAIL") {
            if (!account) return null;
            else if (!xGrailWhitelist.includes(account.toLowerCase())) return null;
          }
          const displayDecimals = tokenData.displayDecimals;
          let price = -1;
          let formattedPrice;
          if (tokenPrices) {
            try {
              price = tokenPrices[address]
                ? tokenPrices[address]
                : 0;
              formattedPrice = Number(price).toLocaleString(undefined, {
                minimumFractionDigits: displayDecimals
              });
            } catch (e) {
              console.log(e);
            }
          }
          return (
            <div
              className={`token-container fade-in`}
              onClick={() => tokenClicked({symbol, price, address, hasOracle, displayDecimals, decimals})}
              key={index}
            >
              <img src={fetchTokenLogo(symbol)} alt={symbol} />
              <div className="token-info">
                {renderTokenSymbol(symbol)}
                {Object.entries(tokenPrices).length > 0 && renderLazyLoad(formattedPrice,
                  <span className="token-price">
                    ${formattedPrice}
                  </span>
                )}
              </div>
            </div>
          );
        } catch (e) {
          return null
        }
      })
    );
  };

  const categoryClicked = (category: number) => {
    setSelectedCategory(tokenCategories[category]);
  }

  const renderCategories = () => {
    return (
      <div className="token-categories-wrapper">
        <LineTabs
          tabs={tokenCategories}
          activeTab={tokenCategories.indexOf(selectedCategory)}
          setActiveTab={categoryClicked}
        />
      </div>
    );
  }

  return (
    <Modal showModal={props.showModal} setShowModal={props.setShowModal}>
      <div className="token-modal-container fade-in">
        <span className="token-modal-header">Select a Token</span>
        <input
          placeholder="Filter by token name or address..."
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
        {renderCategories()}
        <section className="tokens-container">{getTokens()}</section>
      </div>
    </Modal>
  );
};

export default TokenModal;
